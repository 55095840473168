
    import {Component, Prop} from 'vue-property-decorator';
    import {BaseScript} from '@/components/BaseScript';
    import Common from '@/services/Common';

    @Component({
        name: 'Refresh',
    })
    export default class Refresh extends BaseScript {
        @Prop(Object) private readonly searchGlobalProp!: any;
        @Prop(String) private readonly username!: string;
        @Prop(String) private readonly path!: string;

        constructor() {
            super();
        }

        /**
         * @private
         * @return void
         */
        private mounted(): void {
            const routerData: any = {
                name: 'home',
                params: {},
            };

            if (Common.checkIsNotUndifined(this.path)) {
                routerData.name = this.path;
            } else if (Common.getIsVisitor()) {
                routerData.name = 'userhome';
            }

            if (Common.checkIsNotUndifined(this.searchGlobalProp)) {
                routerData.params.searchGlobalProp = this.searchGlobalProp;
            }

            this.$router.push(routerData);
        }
    }
